.part_num_renderer{
    height: 100%;
    display: flex;
    align-items: center;
}

.deactivate_renderer {
    margin-top: 0.5em;
}

.marketplace_renderer {
    .btn {
        display: inline;
    }
}

.hideDisableToggle {
    margin-top: 0.4em;
}

input{
    cursor: pointer !important;
}