@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@use 'src/components/AGM/scss/index.scss';
@use 'src/components/common/Highcharts/highcharts.scss';
$red: awsui.$color-background-notification-red;
$green: awsui.$color-background-notification-green;
$blue: awsui.$color-background-notification-blue;
$lightBlue: awsui.$color-background-toggle-checked-disabled;
$white: awsui.$color-text-button-primary-default;

body {
    margin: 0;
}

.page-header {
    margin-bottom: 1rem;
}
.updated-cell {
    background-color: yellow;
}

#appLayout {
    .ag-header-cell-text {
        white-space: normal;
    }

    #notification_panel {
        display: none;
    }

    div[class^='awsui_panel-wrapper-outer_'] {
        height: calc(100vh - 56px) !important;
    }

    div[class^='awsui_drawer-content_'] {
        top: 56px !important;
    }

    .awsui_panel-wrapper-outer_lm6vo_is764_6 {
        position: static;
    }
    .awsui_drawer-content_lm6vo_is764_117 {
        position: relative;
    }

    .error, .forecast, .sell-thru {
        padding-left: 0.5rem;
    }

    .error {
        background-color: $red;
        color: $white
    }

    .forecast{
        background-color: $blue;
        color: $white;
    }

    .sell-thru{
        background-color: $lightBlue;
    }

    .editable{
        color: $green;
    }

    .negativeValue {
        color: $red
    }

    .ag-body-viewport {
        overflow-x: hidden !important;
    }
}
.ag-theme-alpine .ag-select-list {
    overflow-y: scroll;
    max-height: 50vh !important;
  }

.ag-theme-alpine .ag-row-selected {
    background-color:  #ff9900;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color:#118AB2
}

.auto-suggest input {
    height: 18px !important;
}
.ag-popup-editor .awsui_controls_14mhv_1504x_215 {
    padding-top: 0 !important;
}

.ag-cell .delete-icon, .ag-cell .awsui_icon_vjswe_17xsg_901 {
    padding: 0 !important;
}

.ag-row .delete-icon{
   opacity: 0;
}

.ag-row:hover .delete-icon {
    opacity: 1;
}

.mg-top-md {
    margin-top: 24px;
}

.mg-bottom-md {
    margin-bottom: 24px;
}

.border-bottom {
    border-bottom: 2px solid lightgray;
}
.mid-height {
    height: 100px;
}

.bg-primary:not(:disabled){
    background-color: #EC7211 !important;
    border-color: #EC7211 !important;
}

@media (min-width: 690px){
    .max-width-container {
        box-sizing: initial;
        max-width: 1080px;
        margin: 0 auto !important;
    }
}

.loadspinner {
    text-align: center;
    margin: auto;
    margin-top: 2rem;
}

.rpt-radio-group > div[class*="awsui_radio"],
.rpt-radio-group > span[class*="awsui_radio"]  {
    display: inline-block !important;
    margin-right: 1rem !important;
}

.hr-divider {
    border: none;
    border-top: 1px solid #eaeded;
    margin: 0;
}

.side-nav-header {
    padding: 20px 24px;
    margin: 0;
}

.side-nav-toggle {
    padding: 16px 24px;
}

.muted-text {
    color: grey !important;
}

.border-left {
    border-left: 1px solid #eaeded;
}

.user-list {
    width: 450px;
    display: inline-block;
    border-left: 2px solid #e9ebed;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
}

.bg-white-disabled button:disabled {
    background-color: white !important;
    color: black !important;
}