.alert-fixed-top {
    position: fixed;
    top: 0;
    left: 50%;
    width: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 99;
  }
  