@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
@import '~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin';
.bg-change {
  background-color: #ffeaaf;
}
.bg-unchanged {
  background-color: #fff;
}

.bg-change {
  background-color: #ffeaaf;
}
.bg-unchanged {
  background-color: #fff;
}
.bg-mandatory {
  background-color: #ffffb3;
  border: solid 1px;
  border-color: var(--ag-border-color, #00a1c9) !important;
}
.ag-row {
  &.ag-row-even {
    background-color: #d6eaf8;
  }
  &.ag-row-odd {
    background-color: #ebf5fb;
  }
  .non-editable {
    &-dynamic {
      color: rgb(103, 100, 100);
    }
    color: rgb(103, 100, 100);
  }
}

.ag-theme-alpine .ag-ltr .ag-cell {
  border-right: 1px solid;
  border-right-color: var(
    --ag-row-border-color,
    var(--ag-secondary-border-color, #dde2eb)
  );
}
.ag-body-viewport {
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}
.ag-theme-alpine {


  @include ag-theme-alpine(
    (
      alpine-active-color: #d2dbe8,
      foreground-color: #000,
      disabled-foreground-color: ag-derived(foreground-color, $opacity: 0.3),
      input-disabled-border-color: ag-derived(input-border-color, $opacity: 0.3),
      input-disabled-background-color: #eee,
      header-background-color: #eee,
      odd-row-background-color: #ebf5fb,
      border-color: ag-derived(background-color, $mix: foreground-color 25%),
      secondary-border-color: ag-derived(border-color),
      row-border-color: ag-derived(secondary-border-color),
      borders: true,
      header-column-separator: true,
      input-border-color: null,
      cell-horizontal-border: solid transparent,
      font-size: 12px,
      row-height: ag-derived(grid-size, $times: 5, $plus: 0),
    )
  );
  .ag-header {
    font-weight: 700;
  }
}

.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid;
  border-right-color: var(
    --ag-row-border-color,
    var(--ag-secondary-border-color, #dde2eb)
  );
}

.ag-theme-balham {
  @include ag-theme-balham(
    (
      balham-active-color: #d2dbe8,
      foreground-color: #000,
      range-selection-border-color: #f9b630,
      disabled-foreground-color: ag-derived(foreground-color, $opacity: 0.3),
      input-disabled-border-color: ag-derived(input-border-color, $opacity: 0.3),
      input-disabled-background-color: #eee,
      header-background-color: #eee,
      odd-row-background-color: #ebf5fb,
      range-selection-highlight-color: ag-derived(range-selection-border-color),
      border-color: ag-derived(background-color, $mix: foreground-color 25%),
      secondary-border-color: ag-derived(border-color),
      row-border-color: ag-derived(secondary-border-color),
      borders: true,
      header-column-separator: true,
      input-border-color: null,
      cell-horizontal-border: solid transparent,
      font-size: 12px,
      row-height: ag-derived(grid-size, $times: 5, $plus: 0),
    )
  );
  .ag-header {
    font-weight: 700;
  }
}
.grid-container-height-constraint {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: auto;
}

.grid-auto-height {
  max-height: auto;
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
}
