.assortmentRow {
    .ag-cell{
        padding-top: 5px;
        button {
            padding: 0 !important;
        }
    }
}

.ag-popup-child {
    z-index: 5000;
}