.series-label-content,
.label-title,
.label-sub-title,
.chart-yaxis-label {
    word-break: break-word;
    white-space: normal;
    display: block;
    width: 100%;
}

.series-label-content {
    padding: 0 12px;
    color: white;
}

.label-title {
    font-size: 0.8rem;
}

.label-sub-title {
    font-size: 0.75rem;
}

.subtitle-field {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chart-yaxis-label {
    min-width: 100px;
}